::-webkit-scrollbar,
::-webkit-scrollbar:horizontal,
::-webkit-scrollbar:vertical {
  width: $scroll-bar-size;
  height: $scroll-bar-size;

  &:increment,
  &:decrement {
    display: none;
  }

  &:start:increment,
  &:end:decrement {
    display: none;
  }
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: hslac($gray-color-dark, $opacity-05);
  border-radius: 0.125rem;;
  background-clip: padding-box;
  border: solid 0.25rem transparent;

  &:hover {
    background-color: hslac($gray-color-dark, $opacity-075);
  }

  &:active {
    background-color: $gray-color-dark;
  }
}

::-webkit-scrollbar-thumb:vertical {
  width: 0.5rem;;
  height: 33%;
}

::-webkit-scrollbar-thumb:horizontal {
  height: 0.5rem;
  width: 33%;
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-track {
  background: transparent;
}

.side-modal::-webkit-scrollbar-track {
  background: $white;
}