$primary-hue: 217;
$primary-saturation: 44%;
$primary-lightness: 27%;

$primary-color: hsl($primary-hue, $primary-saturation, $primary-lightness);
$primary-color-hover: hsl($primary-hue, $primary-saturation + 6, $primary-lightness + 8);
$primary-color-outline: hsl($primary-hue, $primary-saturation + 6 , $primary-lightness + 23);
$primary-color-selected: hsl($primary-hue, $primary-saturation + 17, $primary-lightness - 12);
$primary-color-title: hsl($primary-hue, $primary-saturation + 17, $primary-lightness - 17);

$background-order-color-lg: linear-gradient(to bottom, hsl(226, 40%, 28%) 0%, hsl(224, 42%, 27%) 25%, hsl(224, 42%, 27%) 50%, hsl(224, 42%, 27%) 70%, #293863 80%, 	hsl(224, 41%, 27%) 90%, hsl(224, 43%, 27%) 100%);
$background-order-color: linear-gradient(to bottom, hsl(226, 40%, 28%) 0%, hsl(224, 42%, 27%) 25%, hsl(224, 42%, 27%) 50%, hsl(224, 42%, 27%) 70%, #293863 80%, 	hsl(224, 41%, 27%) 90%, hsl(224, 43%, 27%) 100%);

$myabc-logo-url: url('../images/logos/myabc-logotype-se.svg') no-repeat;
$background-url: url('../images/banner-myabc-login-se.jpg') no-repeat top left;
$icon-design-url: url('../images/icons/icn-design-se.svg') no-repeat;
$background-order-url: url('../images/banner-myabc-area-se.jpg') no-repeat 100% 50%;

$thank-you-page-background-url: url('../images/banner-thankyou-se.jpg') no-repeat top right !default;
$thank-you-page-background-color: linear-gradient(196.7deg, hsl(221, 43, 37) 0%, hsl(228, 57, 30) 100%) !default;

@import 'mixins';
@import 'app';
@import 'scrollbars';
@import "~bootstrap/scss/bootstrap";
@import 'vanillajs-datepicker/css/datepicker-bulma.css';
@import "bootstrap-related";
@import "~cropperjs";
@import 'error-layout';
