/* ===== design scheme ===== */
$opacity-0: 0;
$opacity-025: 0.25;
$opacity-05: 0.5;
$opacity-075: 0.75;
$opacity-1: 1;

$gray-color: hsl(225, 10%, 92%);
$gray-color-white: hsl(0, 0%, 99%);
$gray-color-lightest: hsl(0, 0%, 98%);
$gray-color-light: hsl(220, 60%, 15%);
$gray-color-middle: hsl(230, 5%, 25%);
$gray-color-dark: hsl(229, 5%, 55%);
$box-shadow-light-gray-hover: 0 0 0.625rem 0 hslac($gray-color-light, 0.1);

$headings-color: $primary-color-title;
$body-color: $gray-color-middle;

$font-size-base: 1rem !default;
$h1-font-size:   2.875rem !default;
$h2-font-size:   2.25rem !default;
$h3-font-size:   1.625rem !default;
$h4-font-size:   1.25rem !default;
$h5-font-size:   1rem !default;
$h6-font-size:   0.9rem !default;
$headings-line-height: 1.25 !default;

/* ===== general vars ===== */
$menu-height: 3.75rem;
$input-height: 3.125rem;
$validation-success-color: hsl(160, 62%, 63%);
$validation-error-color: hsl(1, 100%, 35%);
$validation-error-font-color: hsl(346, 100%, 35%);
$border-modal: 0.0625rem solid hsl(160, 7%, 92%);
$padding-small: 0.3125rem;
$border-radius: 0.125rem;
$scroll-bar-size: 1rem;

$danger-color: hsl(1, 100%, 50%);
$white: hsl(0, 0%, 100%);

$border-grey: 0.0625rem solid $gray-color;
$border-hover: 0.0625rem solid $gray-color-dark;
$border-focus: 0.0625rem solid $primary-color-outline;
$border-error: 0.0625rem solid $validation-error-color;
$border-valid: 0.0625rem solid $validation-success-color;

$transition-cubic-bezier-02: 0.2s cubic-bezier(0.25, 0, 0.5, 1);

/* ===== general styles ===== */
body {
  min-height:100vh;

  text-rendering: optimizeLegibility;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  cursor: pointer;
}

.hide-scroll {
  overflow: hidden;
}

.icon-margin-right {
  margin-right: 0.3125rem;
}

.icon-margin-left {
  margin-left: 0.3125rem;
}

.icon-modal-p-top {
  padding-top: 0.3125rem;
}

.icon-close {
  height: 1.5rem;
  width: 1.75rem;
  background: url('../images/icons/icn-menu-close.svg') no-repeat;
}

.icon-design {
  height: 1.25rem;
  width: 1.875rem;
  background: $icon-design-url;
}

.icon-retry {
  height: 0.9375rem;
  width: 0.9375rem;
  background: url('../images/icons/icn-repeat.svg') no-repeat;
}

.icon-retry-w {
  height: 0.9375rem;
  width: 0.9375rem;
  background: url('../images/icons/icn-repeat-w.svg') no-repeat;
}

.icon-tooltip {
  min-height: 1.25rem;
  min-width: 1.25rem;
  background: url('../images/icons/icn-question-help.svg') no-repeat;
}

.tooltip-image {
  width: 11.5rem;
  height: 11.5rem;
}

/* ===== font related styles ===== */
@font-face {
  font-family: 'SuperiorTitle-Black';
  font-style: normal;
  font-weight: 900;
  src:
    url('../fonts/superiortitle/SuperiorTitle-Black.woff2') format('woff2'),
    url('../fonts/superiortitle/SuperiorTitle-Black.woff') format('woff');
}

@font-face {
  font-family: 'CalibreWeb-Medium';
  src:
    url('../fonts/calibre/CalibreWeb-Medium.woff2') format('woff2'),
    url('../fonts/calibre/CalibreWeb-Medium.woff') format('woff');
}

@font-face {
  font-family: 'CalibreWeb-Regular';
  src:
    url('../fonts/calibre/CalibreWeb-Regular.woff2') format('woff2'),
    url('../fonts/calibre/CalibreWeb-Regular.woff') format('woff');
}

h1 {
  font-size: 3rem !important;
}

h2 {
  font-size: 2.25rem !important;
}

h3 {
  font-size: 1.625rem !important;
}

h4 {
  font-size: 1.25rem !important;
}

h5 {
  font-size: 1rem !important;
}

.link-color {
  color: $primary-color-title !important;
}

.link-color:hover {
  color: hsl(0, 0%, 5%) !important;
}

.font-bold {
  font-family: CalibreWeb-Medium,Helvetica Neue,Helvetica,Arial,sans-serif;
}

.font-size-smallest {
  font-size: 0.75rem !important;
}

.font-size-small {
  font-size: 0.875rem !important;
}

.font-size-large {
  font-size: 1.125rem !important;
}

.font-size-big {
  font-size: 1.25rem !important;
}

.font-size-biggest {
  font-size: 1.375rem !important;
}

.font-color-primary {
  color: $primary-color !important;
}

.font-color-success {
  color: hsl(115, 80%, 35%) !important;
}

.font-color-title {
  color: $primary-color-title !important;
}

.font-color-selected {
  color: $primary-color-selected !important;
}

.font-color-middle-grey {
  color: $gray-color-middle !important;
}

.font-color-white {
  color: $white !important;
}

.font-color-danger {
  color: $validation-error-color !important;
}

.border-error {
  border: $border-error !important;
}

.line-height-middle {
  line-height: 1.4;
}

.w-10 {
  width: 10% !important;
}

.w-65 {
  width: 65% !important;
}

.w-55 {
  width: 55% !important;
}

.mt-05, .my-05 {
  margin-top: 0.5rem !important;
}

.spinner-abc {
  width: 3rem !important;
  height: 3rem !important;
  border-width: 0.175rem !important;
}

/* ===== block-ui ===== */

.blockUI {
  border: 0 !important;
}

.blockPage {
  background-color: transparent !important;
}

/* ===== dropdown ===== */
.dropdown-container {
  height: $input-height;

  .icon-dropdown {
    width: 0.75rem;
    height: 0.375rem;
    background: url('../images/icons/icn-triangle.svg') no-repeat;
  }

  .dropdown-menu {
    max-height: 14.5625rem;
    overflow-y: auto;
    box-shadow: 0 0.3125rem 0.9375rem 0 hslac($primary-color-title, 0.05), 0 0.3125rem 1.875rem 0 hslac($primary-color-title, 0.01), 0 0 0.625rem 0 hslac($primary-color-title, 0.15);
    border: 0.0625rem solid $gray-color !important;
  }

  button {
    border: $border-grey;
  }

  button:hover {
    border: $border-hover;
  }

  button:focus {
    border: $border-focus;
  }
}

/* ===== custom textarea ===== */

.textarea-container {
  textarea {
    background-color: hslac($gray-color, $opacity-025);
    border: $border-grey;
  }

  textarea:hover {
    border: $border-hover;
  }

  textarea:focus {
    border: $border-focus;
  }
}

/* ===== custom input ===== */

.input-container {
  height: $input-height;

  input {
    background-color: hslac($gray-color, $opacity-025);
    border: $border-grey;
    border-radius: $border-radius !important;
  }

  input:hover {
    border: $border-hover;
  }

  input:focus, input:focus:hover {
    background-color: $white;
    border: $border-focus;
  }
}

/* ===== custom input append ===== */

.input-container-append {
  height: $input-height;

  &:hover {
    .form-control {
      border: $border-hover;
      border-right: 0;
    }

    .input-group-append {
      .input-group-text {
        border: $border-hover;
        border-left: 0;
      }
    }
  }

  .form-control {
    background-color: hslac($gray-color, $opacity-025);
    border: $border-grey;
    border-right: 0;
    transition: none;
  }

  .form-control:focus, .form-control:focus:hover {
    background-color: $white;
    border: $border-focus;
    border-right: 0;
  }

  .form-control:focus + .input-group-append {
    .input-group-text {
      border: $border-focus;
      border-left: 0;
    }
  }

  .form-control:focus:hover + .input-group-append {
    .input-group-text {
      border: $border-focus;
      border-left: 0;
    }
  }

  .input-group-append {
    .input-group-text {
      border: $border-grey;
      border-left: 0;
      border-radius: 0 $border-radius $border-radius 0 !important;
      background-color: hslac($gray-color, $opacity-05);
      width: $input-height;

      .icon-info {
        height: 1.25rem;
        width: 1.25rem;
        background: url('../images/icons/icn-info.svg') no-repeat;
      }
    }
  }
}

.was-validated {
  .input-container-append {
    .form-control:invalid + .input-group-append {
      .input-group-text {
        border: $border-error;
        border-left: 0;
      }
    }

    .form-control:valid + .input-group-append {
      .input-group-text {
        border: $border-valid;
        border-left: 0;
      }
    }
  }
}

/* ===== custom file input ===== */

.custom-file-container {
  height: $input-height;

  .custom-file-label:after {
    height: 100%;
    padding-top: 0.75rem;
  }

  .custom-file-label {
    background-color: hslac($gray-color, $opacity-025);
    border: $border-grey;
  }

  .custom-file-label:hover {
    border: $border-hover;
  }

  .custom-file-label:focus {
    background-color: $white;
    border: $border-focus;
  }
}

/* ===== custom checkbox ===== */

.custom-checkbox-container {
  $checkbox-size: 1.5rem;

  .custom-control {
    min-height: 2rem;

    .custom-control-label::before {
      height: $checkbox-size;
      width: $checkbox-size;
      background-color: hslac($gray-color, $opacity-025);
      border: $border-grey;
    }

    .custom-control-label::before:focus {
      border: $border-focus;
    }

    .custom-control-label::after {
      height: $checkbox-size;
      width: $checkbox-size;
    }

    .custom-control-input {
      width: $checkbox-size;
      height: 1.75rem;
    }

    span {
      padding-top: 0.1875rem;
      padding-left: 0.1875rem;
    }

    .invalid-feedback {
      padding-left: 0.1875rem;
    }
  }
}

/* ===== form container ===== */

.form-container {
  .was-validated {
    .invalid-feedback, .text-danger {
      color: $validation-error-font-color;
    }

    .dropdown-container {
      button, button:focus, button:active {
        border-color: $validation-success-color;
      }
    }

    .form-control:invalid {
      background-image: none;
    }
  }
}

/* ===== general modal styles  ===== */

.modal-backdrop, .blockOverlay {
  background: linear-gradient(45deg, $primary-color-selected 0%, $primary-color-title 100%);
  box-shadow: inset 0 0 6.25rem 0 hslac($primary-color-selected, $opacity-05);
}

.modal-close {
  padding-top: 0.3125rem;
  margin-right: -0.9375rem;

  a {
    background: $white;
    cursor: pointer;
    height: 3.125rem;
    width: 3.125rem;

    &:hover {
      background: hslac($gray-color, $opacity-05);
    }

    &:focus, &:active {
      background: hslac($gray-color, $opacity-075);
    }

    .icon-close {
      margin-top: 0.25rem;
    }
  }
}

/* ===== notification modal ===== */

.modal-notification {
  $icon-size: 2.5rem;

  .header-element {
    border-bottom: $border-modal;

    h3 {
      padding-top: 0.5rem;
    }
  }

  .button-container {
    height: $input-height !important;
  }

  .button-container-width {
    width: 49%;
  }

  .icon-success {
    min-height: $icon-size;
    min-width: $icon-size;
    background: url('../images/icons/icn-check-success.svg') no-repeat;
  }

  .icon-info {
    min-height: $icon-size;
    min-width: $icon-size;
    background: url('../images/icons/icn-info.svg') no-repeat;
  }

  .icon-error {
    min-height: $icon-size;
    min-width: $icon-size;
    background: url('../images/icons/icn-error.svg') no-repeat;
  }

  .hat-measure-iframe {
    position: relative;
    padding-bottom: 56.25%; /* proportion value to aspect ratio 16:9 (9 / 16 = 0.5625 or 56.25%) */
    height: 0;
    overflow: hidden;
  }

  .hat-measure-iframe iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

/* ===== hidden button ===== */

.hidden-container {
  height: 0 !important;
  width: 0 !important;

  .hidden-element {
    visibility: hidden;
    height: 0;
    width: 0;
  }
}

/* ===== complaint/order delete  modal===== */

.modal-complaint, .modal-delete-order {
  .modal-dialog {
    width: 42.5rem;
  }

  .modal-content {
    border-radius: 0;
  }

  .header-element-blank {
    min-height: $menu-height;
  }

  .footer-element-blank {
    min-height: $menu-height;
  }

  .header-element {
    height: $menu-height;
    left: auto;
    border-bottom: $border-modal;
    background-color: $white;

    h3 {
      padding-top: 0.5rem;
    }
  }

  .footer-element {
    height: $menu-height;
    left: auto;
    border-top: $border-modal;
    background-color: $white;

    .button-container {
      width: 49%;
      height: $input-height !important;
    }
  }

  .modal-body {
    background: linear-gradient(134.71deg, $gray-color-white 0%, $white 100%);

    .nav-list-container {
      box-shadow: 0 0 0.625rem 0 hslac($gray-color-light, 0.03);
      border-radius: $border-radius;
      border: $border-grey;

      .button-container {
        height: $input-height !important;
        width: calc(100%/2);

        .btn {
          border-radius: 0;
        }

        &:first-child {
          border-right: $border-grey;

          button {
            border-radius: $border-radius 0 0 $border-radius;
          }
        }

        &:last-child {
          border-left: $border-grey;

          button {
            border-radius: 0 $border-radius $border-radius 0;
          }
        }
      }
    }

    .fitting-video-block {
      height: 5.9375rem;
      border-radius: $border-radius;
      box-shadow: 0 0.625rem 0.625rem 0 hslac(hsl(221, 15%, 21%), 0.03);
      border: 0.0625rem solid hslac($gray-color, $opacity-075);
      cursor: pointer;
      transition: $transition-cubic-bezier-02;
      background: $white;

      .fitting-video-box {
        height: 3.9375rem;
        padding-left: 1rem;
        padding-right: 1rem;

        .fitting-video-thumb {
          width: 4.6875rem;
          border-radius: 0.25rem;
          background-image: url('../images/size-play-thumb.png');
          background-size: cover;

          .icon-play {
            width: 2.5rem;
            height: 2.5rem;
            background-image: url('../images/icons/icn-play.svg');
          }
        }
      }

      .fitting-video-text-box {
        padding-left: 0.75rem;
      }

      &:hover {
        box-shadow: $box-shadow-light-gray-hover;
      }
    }
  }
}

/* ===== order page ===== */

.order-container {
  background-color: hslac($gray-color, $opacity-025);
}

.order {
  $icon-width: 2.8125rem;

  .logo {
    color: $white;
    min-height: 11.25rem;
  }

  .logo-container {
    min-height: 11.25rem;
  }

  .item-status-container {
    min-height: 26.375rem;
  }

  .item-element {
    background-color: $white;
  }

  .iframe-hat-spinner, .iframe-hat {
    height: 100%;
    width: 100%;
  }

  .item-status {
    min-height: 5rem;
    background-color: $white;

    .button-container {
      height: 2.5rem;
    }
  }

  .item-status:last-child {
    min-height: 9.4375rem;
  }

  .icon {
    min-width: $icon-width;
    min-height: 2.5rem;
  }

  .icon-status {
    background: url('../images/icons/icn-order-status.svg') no-repeat;
  }

  .icon-payment {
    background: url('../images/icons/icn-payment-status.svg') no-repeat;
  }

  .icon-delivery {
    background: url('../images/icons/icn-deliver-status.svg') no-repeat;
  }

  .icon-address {
    background: url('../images/icons/icn-address.svg') no-repeat;
  }

  .icon-link {
    height: 0.9rem;
    width: 0.9rem;
    background: url('../images/icons/icn-open-new-window.svg') no-repeat;
  }

  .icon-camera {
    height: 1.5rem;
    width: 1.5rem;
    background: url('../images/icons/icn-camera.svg') no-repeat;
  }

  .icon-eye {
    height: 1.5rem;
    width: 1.5rem;
    background: url('../images/icons/icn-eye-preview.svg') no-repeat;
  }

  .icon-lasercut {
    height: 0.875rem;
    width: 0.625rem;
    background: url('../images/icons/icn-engraving.svg') no-repeat;
  }

  .cart-style {
    border-radius: 0.1875rem;
    border: 0.0625rem solid hslac($gray-color, 0.65);
    box-shadow: 0 0 0.625rem 0 hslac($gray-color-light, 0.03);
  }

  .order-list {
    background-color: $white;

    .table {
      td {
        padding-left: 0;
        padding-right: 0;
        border-top: 0.0625rem solid hslac($gray-color, $opacity-05);
      }

      td span {
        padding-left: 0;
        padding-right: 0;
      }

      .first-variant td {
        border-top: 0;
      }

      .price-column {
        padding-top: 0.1875rem;
      }

      del {
        color: hslac($gray-color-dark, $opacity-05) !important;
      }

      .sum {
        font-size: 1.5625rem;
      }

      .sum-total {
        color: hslac($gray-color-light, 0.5);
      }
    }

    .variants-table {
      .button-container-photo {
        height: 2.5rem;
        width: 3.125rem;

        .border-active {
          border: 0.0625rem solid hslac(hsl(1, 100%, 35%), $opacity-05);
        }
      }
    }

    .photo-upload {
      .photo-not-uploaded {
        height: 25rem;
        border: 0.0625rem solid hslac(hsl(1, 100%, 35%), $opacity-05);
        background: hslac($gray-color, $opacity-025);
        cursor: pointer;
      }

      .upload-container {
        height: 12.5rem;
        border-radius: $border-radius;
        border: $border-grey;
        background: hslac($gray-color, $opacity-025);
        cursor: pointer;
      }

      .button-container {
        height: 2.5rem;
      }
    }
  }

  .button-order-p-right {
    padding-right: $padding-small;
  }

  .button-order-p-left {
    padding-left: $padding-small;
  }

  .btn-group {
    &.justify-content-end {
      .button-container {
        &:not(:first-child) {
          margin-left: 1rem;
        }
      }
    }
  }

  .button-order-container {
    .button-container {
      height: 2.5rem;
    }
  }
}

/* ===== login page ===== */

footer {
  $flag-height: 0.9375rem;
  $flag-width: 1.25rem;

  background: $white;
  height: $menu-height;

  .dropdown-container {
    height: 2.75rem;
  }

  .flag {
    height: $flag-height;
    width: $flag-width;
  }

  .flag-sv {
    background: url('../images/flag-sv.jpg') no-repeat;
    background-size: $flag-width $flag-height;
  }

  .flag-da {
    background: url('../images/flag-da.jpg') no-repeat;
    background-size: $flag-width $flag-height;
  }

  .flag-en {
    background: url('../images/flag-en.jpg') no-repeat;
    background-size: $flag-width $flag-height;
  }
}

.login {
  .form, .form-mobile-container {
    background: $gray-color-lightest;
  }

  .form-desktop {
    width: 36.25rem;
    height: 25.875rem;
    box-shadow: 0 0.3125rem 1.875rem 0 hslac($gray-color-middle, 0.15);
  }

  .button-container {
    height: $input-height;
  }

  .icon-arrow-login {
    height: 0.625rem;
    width: 1rem;
    background: url('../images/icons/icn-arrow-right-white.svg') no-repeat;
  }

  .icon-email {
    height: 0.75rem;
    width: 0.9375rem;
    background: url('../images/icons/icn-email.svg') no-repeat;
  }

  .icon-phone {
    height: 0.9375rem;
    width: 0.9375rem;
    background: url('../images/icons/icn-phone.svg') no-repeat;
  }
}

/* ===== header ===== */

header {
  background: $white;
  height: $menu-height;

  border: 0.0625rem solid hslac($gray-color, 0.65);
  box-shadow: 0 0 0.625rem 0 hslac($gray-color-light, 0.03);

  .button-container {
    height: 2.5rem;
  }

  .logo {
    width: 8.5625rem;
    height: 2rem;
    background: $myabc-logo-url;
  }

  .icon-logout {
    height: 1rem;
    width: 1rem;
    background: url('../images/icons/icn-logout.svg') no-repeat;
  }

  .icon-arrow-back {
    height: 0.625rem;
    width: 1rem;
    background: url('../images/icons/icn-arrow-right-black.svg') no-repeat;
    transform: scaleX(-1);
  }

  .header-mobile {
    .screen-size {
      height: calc(100vh - #{$menu-height});
      background: linear-gradient(134.71deg, $gray-color-white 0%, $white 100%);
      margin-top: 0.5rem;
    }

    .nav-background {
      background-color: $white;
    }

    .icon-menu {
      height: 2.5rem;
      width: 2.5rem;
      background: url('../images/icons/icn-menu.svg') no-repeat;
      background-size: 100% 100%;
    }

    .icon-menu-close {
      height: 2.5rem;
      width: 2.5rem;
      background: url('../images/icons/icn-menu-close.svg') no-repeat;
    }

    .button-container-m {
      height: 2.5rem;
    }
  }
}

.header-blank {
  height: $menu-height;
}

/* ===== tooltip ===== */
.tooltip {
  opacity: 1 !important;

  .arrow::before {
    border: 0 !important;
  }

  .tooltip-inner {
    color: $gray-color-middle;
    background-color: $white ;
    border: $border-grey;
    box-shadow: 0 0.3125rem 0.625rem 0 hslac($gray-color-light, 0.05), 0 0.125rem 1.25rem 0 hslac($gray-color-light, 0.05);
    text-align: left;
    max-width: 20rem;
    padding: 0.6rem 1rem;
  }

  .preview-container {
    height: 12.5rem;
    width: 12.5rem;
  }
}

.font-color-gray-middle {
  color: $gray-color-middle !important;
}

/* ===== cropper ===== */

.cropper-container {
  width: 100% !important;
}

/* ===== overrides and re-use of boostrap styling ===== */

$font-family-sans-serif: "CalibreWeb-Regular", "Helvetica Neue", Helvetica, Arial, sans-serif;
$headings-font-family: "SuperiorTitle-Black", Georgia, "Times New Roman", Times, serif;
$btn-border-radius-lg: $border-radius;

$spacer: 0.625rem;
$spacers: (0:0);
@for $i from 1 through 20 {
  $spacers: map-merge($spacers, (
    $i: ($i * $spacer)
  ));
}

$theme-colors: (
  "secondary": hslac($gray-color, $opacity-05),
  "info": $primary-color,
  "primary": $primary-color,
  "light": hslac($gray-color, $opacity-025),
  "dark": $primary-color-title,
  "whiteness": $white,
  "success": $validation-success-color,
);

$container-max-widths: (
  xl: 1360px,
);

$green: $validation-success-color;
$red: $validation-error-color;
