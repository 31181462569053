/* ===== header ===== */

@include media-breakpoint-between(xs, md) {
  header {
    .button-container {
      width: 3.875rem;
    }
  }
}

@include media-breakpoint-between(md, xl) {
  header {
    .button-container {
      width: 8.75rem;
    }
  }
}

/* ===== footer ===== */

@include media-breakpoint-between(xs, sm) {
  footer {
    border-top: $border-modal;
  }
}

@include media-breakpoint-between(xs, md) {
  footer {
    .dropdown-container {
      width: 100%;
    }
  }
}

@include media-breakpoint-between(lg, xl) {
  footer {
    .dropdown-container {
      width: 11.5625rem;
    }
  }
}

/* ===== login ===== */

@include media-breakpoint-between(xs, sm) {
  .login-container {
    background: none;
  }
}

@include media-breakpoint-between(md, xl) {
  .login-container-bg {
    background-color: $primary-color;
  }

  .login-container {
    background: $background-url;
    background-size: cover;
  }
}

/* ===== order ===== */

@include media-breakpoint-between(xs, lg) {
  .order {
    .logo-container {
      background: $background-order-color;
    }
  }
}

@include media-breakpoint-up(xl) {
  .order {
    .logo-container {
      background: $background-order-color-lg;
    }
  }
}

@include media-breakpoint-between(lg, xl) {
  .order {
    .item-p-right {
      padding-right: $padding-small;
    }

    .item-p-left {
      padding-left: $padding-small;
    }

    .logo {
      background: $background-order-url;
      background-size: 80%;
    }
  }
}

@include media-breakpoint-between(md, xl) {
  .order {
    .button-order-container {
      width: 11.25rem;
    }

    .item-hat-container {
      min-height: 26.375rem;
    }
  }
}

@include media-breakpoint-between(xs, sm) {
  .order {
    .order-list-col {
      padding-left: 0;
      padding-right: 0;
    }

    .button-order-container {
      width: 100%;
    }

    .item-hat-container {
      min-height: 17rem;
      padding-left: 0;
      padding-right: 0;
    }

    .item-status-container {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

@include media-breakpoint-between(xs, md) {
  .order {
    .item-hat-container {
      margin-top: 0 !important;
    }
  }
}

/* ===== complaint/order delete modal===== */

@include media-breakpoint-between(xs, md) {
  .modal-complaint, .modal-delete-order {
    padding: 0;

    .modal-lg {
      max-width: none;
    }

    .modal-dialog {
      width: 100%;
    }

    .header-element {
      width: 100%;
    }

    .footer-element {
      width: 100%;
    }
  }
}

@include media-breakpoint-between(lg, xl) {
  .modal-complaint, .modal-delete-order {
    .header-element {
      width: 42.5rem;
    }

    .footer-element {
      width: 42.5rem;
    }
  }
}

@include media-breakpoint-between(xs, xs) {
  .modal-complaint, .modal-delete-order {
    .nav-list-container {
      .button-container {
        button {
          span {
            font-size: 0.875rem !important;
          }
        }
      }
    }
  }
}
/* ===== notification modal ===== */

@include media-breakpoint-between(xs, md) {
  .modal-notification {
    padding: 0;

    .modal-lg {
      max-width: none;
      height: 100%;
      width: 100%;
      margin: 0;
    }

    .modal-content {
      width: 100%;
      height: 100%;
      border: 0;
      border-radius: 0;
    }

    .modal-container {
      background: $white;
      border-radius: 0;
    }

    .header-element {
      min-height: $menu-height;
    }
  }
}

@include media-breakpoint-between(lg, xl) {
  .modal-notification {
    .modal-lg {
      max-width: none;
      width: 42.5rem;
    }

    .modal-content {
      border-radius: $border-radius;
    }

    .modal-container {
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }

    .modal-container {
      background: linear-gradient(134.71deg, #FCFCFC 0%, #FFF 100%);
    }

    .header-element {
      height: $menu-height;
    }
  }
}


/* ===== datepicker ===== */
.datepicker {
  .datepicker-view {
    width: 100%;
  }
  &.datepicker-inline {
    width: 100%;
  }
  table {
    &.table-condensed {
      width: 100%;
    }
    tr {
      td {
        &.disabled,
        &.disabled:hover {
          background: none;
          color: #C39494;
          cursor: default;
        }
      }
    }
  }
}

/* ===== header with bootstrap var dependencies===== */

header {
  .header-mobile {
    z-index: calc(#{$zindex-modal} - 1);
  }
}

/* ===== block-ui ===== */

.blockUI {
  z-index: calc(#{$zindex-tooltip} + 1) !important;
}

/* ===== btn-hover ===== */

.btn-info-abc {
  @include button-variant($primary-color-title, $primary-color-title, $primary-color-hover, $primary-color-hover, $primary-color, $primary-color);

  &:hover, &:focus {
    box-shadow: none !important;
  }
}

.btn-secondary-abc {
  @include button-variant(hslac($gray-color, $opacity-05), hslac($gray-color, $opacity-05), hslac($gray-color, $opacity-075), hslac($gray-color, 0.75), hslac($gray-color, $opacity-1), hslac($gray-color, $opacity-1));
  border: 0 !important;

  &:hover, &:focus {
    box-shadow: none !important;
    border: 0 !important;
  }
}

.btn-dark-abc {
  @include button-variant($primary-color-title, $primary-color-title, $primary-color-selected, $primary-color-selected, $primary-color, $primary-color);

  &:hover, &:focus {
    box-shadow: none !important;
  }
}

.btn-whiteness-abc {
  @include button-variant($white, $white, $gray-color-lightest, $gray-color-lightest, $gray-color, $gray-color);

  &:hover, &:focus {
    box-shadow: none !important;
  }
}
/* ===== block-ui ===== */

.block-container {
  z-index: 2000;
  border: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: hslac(hsl(0, 0%, 0%), $opacity-075);
  cursor: wait;
  position: fixed;
}

.block-msg {
  z-index: 2011;
  position: fixed;
  padding: 0;
  margin: 0;
  width: 30%;
  top: 40%;
  left: 35%;
  text-align: center;
  color: hsl(0, 0%, 0%);
  border: 0;
  cursor: wait;
  background-color: transparent;
}