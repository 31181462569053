.simple-layout {
  span, p, h1, h2, h3, h4, h5, h6 {
    color: $white;
  }

  .word-break-all {
    word-break: break-all;
  }

  @include media-breakpoint-between(xs, md) {
    background: $thank-you-page-background-color;
  }

  @include media-breakpoint-between(lg, xl) {
    background: $thank-you-page-background-url;
    background-size: cover;
  }

  header {
    height: 3.75rem;

    background: transparent;
    border: none;
    box-shadow: none;

    .icon-logotype-desktop {
      top: 0;
      width: 4.8125rem !important;
      height: 2.375rem !important;
      background-repeat: no-repeat;
      background-image: url('../images/icons/icn-abc-logotype-desktop.svg');
    }

    a {
      padding-left: 2.5rem;
    }

    a, a:hover, a:active {
      color: $white;
    }

    > .container {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      @include media-breakpoint-between(xs, md) {
        justify-content: space-between;
      }

      @include media-breakpoint-between(lg, xl) {
        justify-content: flex-start;
      }
    }
  }

  main {
    > .container {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    }

    .main-content {
      @include media-breakpoint-between(xs, md) {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
      }

      @include media-breakpoint-between(lg, xl) {
        padding: 2.5rem;
      }

      width: 100%;

      .button-container {
        margin-left: -1rem;
        @include media-breakpoint-between(xs, md) {
          flex-direction: column;
        }

        @include media-breakpoint-between(lg, xl) {
          flex-direction: row;
        }

        .btn-element {
          margin-top: 1rem;
          margin-left: 1rem;
          @include media-breakpoint-between(xs, md) {
            width: 14rem;
          }

          @include media-breakpoint-between(lg, xl) {
            width: 16.5rem;
          }
        }

        &.shop-dates {
          padding-top: 1.75rem;
        }

        &.shop-buttons {
          padding-top: 2.5rem;
        }
      }
    }
  }

  footer {
    background: $white;
    height: 3.75rem;
    border-top: 0.0625rem solid hsl(160, 7, 92);
    > .container {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    }
  }

  .simple-layout-text {
    max-width: 34rem;
  }
}